<template>
<div v-if="show" class="summary bg-white text-center d-flex justify-content-center">
	<div class="loadingio-spinner-pulse-default">
        <div class="ldio-default">
            <div></div>
            <div></div>
            <div></div>
        </div>
    </div>
</div>
</template>

<script>
	export default {
		props: {
			show: {
				type: Boolean,
				default: true
			}
		}
	}
</script>