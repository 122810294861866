<template>
<div class="report report-penjualan">
    <div class="card">
        <div class="card-header border-0 pt-6 bg-white row m-0">
            <div class="d-flex p-0">
                <div class="card-title row text-gray-600">
                    <div class="filter d-flex align-items-center position-relative fs-6">
                        <div class="filter-item">
                            <span>Toko</span>
                            <el-select v-model="filter.store_id" placeholder="Pilih Toko" @clear="onFilter()" clearable @change="onFilter">
                                <el-option v-for="(o, i) in listStore" :key="i" :value="o.id" :label="o.name"/>
                            </el-select>
                        </div>
                        <div class="filter-item">
                            <span>Brand</span>
                            <el-select v-model="filter.brand_id" @change="onFilter()" @clear="onFilter()" placeholder="Brand" clearable style="width: 200px;">
                                <el-option v-for="(o, i) in listBrand" :key="i" :value="o.id" :label="o.name" />
                            </el-select>
                        </div>
                        <div class="filter-item">
                            <span>Pembayaran</span>
                            <el-select v-model="filter.payment_id" @change="onFilter()" @clear="onFilter()" clearable placeholder="Pembayaran" style="width: 200px;">
                                <el-option v-for="(o, i) in listPaymentMethod" :key="i" :value="o.id" :label="o.name" />
                            </el-select>
                        </div>
                        <div class="filter-item">
                            <span>Tanggal</span>
                            <el-date-picker
                              class="form-control"
                              v-model="filter.date"
                              type="daterange"
                              unlink-panels
                              range-separator="-"
                              start-placeholder="Start date"
                              end-placeholder="End date"
                              :shortcuts="dateShortcuts"
                              :size="'large'"
                              clearable
                              @change="onFilter()"
                              format="DD/MM/YYYY"
                              value-format="YYYY-MM-DD"
                            />
                        </div>
                        <div class="d-flex">
                            <button class="btn btn-icon btn-light-primary me-3" @click="onFilter('product'), currentPage = 1"><i class="fas fa-search"></i></button>
                            <button class="btn btn-icon btn-light-danger" @click="resetFilter('product')"><i class="fas fa-times"></i></button>
                        </div>
                    </div>
                </div>
                <div class="card-toolbar" style="margin-left:auto">
                    <div class="row g-2 justify-content-end" data-kt-table-toolbar="base">
                        <div class="col-auto">
                            <router-link to="/laporan/penjualan/summary" target="blank" class="btn btn-bg-light btn-color-dark btn-active-secondary"><i class="fas fa-external-link-alt me-2"></i>Ringkasan Semua Waktu</router-link>
                        </div>
                        <div class="col-auto">
                            <button
                              class="btn btn-primary"
                              @click="exportExcel"
                              :data-kt-indicator="loadingSubmit ? 'on' : 'off'"
                            >
                                <span class="indicator-label">
                                    <span class="svg-icon svg-icon-2">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                            <rect opacity="0.3" x="12.75" y="4.25" width="12" height="2" rx="1" transform="rotate(90 12.75 4.25)" fill="black" />
                                            <path d="M12.0573 6.11875L13.5203 7.87435C13.9121 8.34457 14.6232 8.37683 15.056 7.94401C15.4457 7.5543 15.4641 6.92836 15.0979 6.51643L12.4974 3.59084C12.0996 3.14332 11.4004 3.14332 11.0026 3.59084L8.40206 6.51643C8.0359 6.92836 8.0543 7.5543 8.44401 7.94401C8.87683 8.37683 9.58785 8.34458 9.9797 7.87435L11.4427 6.11875C11.6026 5.92684 11.8974 5.92684 12.0573 6.11875Z" fill="black" />
                                            <path d="M18.75 8.25H17.75C17.1977 8.25 16.75 8.69772 16.75 9.25C16.75 9.80228 17.1977 10.25 17.75 10.25C18.3023 10.25 18.75 10.6977 18.75 11.25V18.25C18.75 18.8023 18.3023 19.25 17.75 19.25H5.75C5.19772 19.25 4.75 18.8023 4.75 18.25V11.25C4.75 10.6977 5.19771 10.25 5.75 10.25C6.30229 10.25 6.75 9.80228 6.75 9.25C6.75 8.69772 6.30229 8.25 5.75 8.25H4.75C3.64543 8.25 2.75 9.14543 2.75 10.25V19.25C2.75 20.3546 3.64543 21.25 4.75 21.25H18.75C19.8546 21.25 20.75 20.3546 20.75 19.25V10.25C20.75 9.14543 19.8546 8.25 18.75 8.25Z" fill="#C4C4C4" />
                                        </svg>
                                    </span>
                                    Export
                                </span>
                                <span class="indicator-progress">
                                  Please wait...
                                  <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                                </span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card-body pt-0 tab-content">
            <div ref="tableRef" class="table-fixed-header table-responsive">
                <Datatable 
                    :table-header="tableHeader" 
                    :table-data="tableData"
                    :loading="loadingTable"
                    :rows-per-page="pagination.per_page"
                    :total="pagination.total_page"
                    :enable-items-per-page-dropdown="false"
                >
                    <template v-slot:cell-index="{ row: data }">
                        <span >{{ data.index }}</span>
                    </template>
                    <template v-slot:cell-store="{ row: data }">
                        {{ data.store }}
                    </template>
                    <template v-slot:cell-tgl="{ row: data }">
                        {{ data.tgl }}
                    </template>
                    <template v-slot:cell-kode_inv="{ row: data }">
                        {{ data.kode_inv }}
                    </template>
                    <template v-slot:cell-payment_method="{ row: data }">
                        <span>{{ data.payment_method }}</span>
                        <span v-if="data.qris"> - {{ data.qris }}</span>
                        <span v-if="data.bank"> - {{ data.bank }}</span>
                    </template>
                    <template v-slot:cell-total_qty="{ row: data }">
                        {{ data.total_qty }}
                    </template>
                    <template v-slot:cell-total="{ row: data }">
                        {{ formatIDR(data.total, { prefix: '' }) }}
                    </template>
                    <template v-slot:cell-status="{ row: data }">
                         <span class="badge " :class="data.status == 1 ? 'badge-info' : data.status == 2 ? 'badge-primary' : data.status == 3 ? 'badge-success' : data.status == 4 ? 'badge-danger' : 'badge-warning'">
                            {{ data.status == 1 ? 'Diproses' : data.status == 2 ? 'Ready' : data.status == 3 ? 'Selesai' : data.status == 4 ? 'Dibatalkan' : 'Pending' }}
                         </span>
                    </template>
                </Datatable>
            </div>
            <div class="text-center mt-7" v-show="tableData.length > 0">
                <el-pagination 
                    background 
                    layout="prev, pager, next" 
                    :page-count="pagination.total_page" 
                    @next-click="handlePageChange" 
                    @prev-click="handlePageChange" 
                    @current-change="handlePageChange" 
                    @update:current-page="pagination.page"
                />
            </div>
        </div>
    </div>

    <LoadingSummary v-if="loadingSummary" />

    <div v-else>
        <div class="summary">
            <div class="summary-list-fixed row m-0">
                <div class="col-xs-12 col-sm-6 col-md-4 col-lg-4 fs-4 fw-bolder text-gray-700">
                    <div class="d-flex">
                        <span class="text-uppercase">Total Transaksi</span>
                        <span class="ms-auto text-nowrap">{{ summary.total_inv }}</span>
                    </div>
                </div>
                <div class="col-xs-12 col-sm-6 col-md-4 col-lg-4 fs-4 fw-bolder text-gray-700">
                    <div class="d-flex">
                        <span class="text-uppercase">Total Harga Tag</span>
                        <span class="ms-auto text-nowrap">{{ formatIDR(summary.total_gross, { prefix: 'Rp'}) }}</span>
                    </div>
                    <div class="d-flex">
                        <span class="text-uppercase">Total Diskon</span>
                        <span class="ms-auto text-nowrap">{{ formatIDR(summary.total_discount, { prefix: 'Rp' }) }}</span>
                    </div>
                </div>
                <div class="col-xs-12 col-sm-6 col-md-4 col-lg-4 fs-4 fw-bolder text-gray-700">
                    <div class="d-flex">
                        <span class="text-uppercase">Total NETT</span>
                        <span class="ms-auto text-nowrap">{{ formatIDR(summary.total_nett, { prefix: 'Rp'}) }}</span>
                    </div>
                    <div class="d-flex">
                        <span class="text-uppercase">Total Qty</span>
                        <span class="ms-auto text-nowrap">{{ summary.total_qty }} PCS</span>
                    </div>
                </div>
            </div>
        </div>

        <!--begin::Accordion-->
        <div class="accordion mb-4" id="kt_accordion_1">
          <div class="accordion-item">
            <h2 class="accordion-header" id="kt_accordion_1_header_1">
              <button
                class="accordion-button fs-4 fw-bolder bg-white text-gray-700 text-hover-primary text-uppercase"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#kt_accordion_1_body_1"
                aria-expanded="true"
                aria-controls="kt_accordion_1_body_1"
              >
                Toko
              </button>
            </h2>
            <div
              id="kt_accordion_1_body_1"
              class="accordion-collapse collapse"
              aria-labelledby="kt_accordion_1_header_1"
              data-bs-parent="#kt_accordion_1"
            >
                <div class="accordion-body p-0">
                    <div class="summary m-0">
                        <div class="summary-list-fixed row m-0">
                            <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3 fs-4 fw-bolder text-gray-700" v-for="(o, i) in summary.store" :key="i">
                                <div class="d-flex">
                                    <span class="text-uppercase">{{ o.store_name }}</span>
                                    <span class="ms-auto">{{ o.total ? formatIDR(o.total, { prefix: 'Rp'}) : 0 }}</span>
                                </div>
                                <div class="d-flex text-gray-400 text-uppercase">
                                    <span>Qty</span>
                                    <span class="ms-auto text-nowrap">{{ o.qty }} PCS</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
          </div>
        </div>
        <!--end::Accordion-->

        <!--begin::Accordion-->
        <div class="accordion mb-4" id="kt_accordion_2">
          <div class="accordion-item">
            <h2 class="accordion-header" id="kt_accordion_2_header_2">
              <button
                class="accordion-button fs-4 fw-bolder bg-white text-gray-700 text-hover-primary text-uppercase"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#kt_accordion_2_body_2"
                aria-expanded="true"
                aria-controls="kt_accordion_2_body_2"
              >
                Tipe Produk
              </button>
            </h2>
            <div
              id="kt_accordion_2_body_2"
              class="accordion-collapse collapse"
              aria-labelledby="kt_accordion_2_header_2"
              data-bs-parent="#kt_accordion_2"
            >
                <div class="accordion-body p-0">
                    <div class="summary m-0">
                        <div class="summary-list-fixed row m-0">
                            <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3 fs-4 fw-bolder text-gray-700" v-for="(o, i) in summary.tipe_produk" :key="i">
                                <div class="d-flex">
                                    <span class="text-uppercase">{{ o.tipe_produk }}</span>
                                    <span class="ms-auto">{{ o.total ? formatIDR(o.total, { prefix: 'Rp'}) : 0 }}</span>
                                </div>
                                <div class="d-flex text-gray-400 text-uppercase">
                                    <span>Qty</span>
                                    <span class="ms-auto text-nowrap">{{ o.qty }} PCS</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
          </div>
        </div>
        <!--end::Accordion-->

        <!--begin::Accordion-->
        <div class="accordion mb-4" id="kt_accordion_3">
          <div class="accordion-item">
            <h2 class="accordion-header" id="kt_accordion_3_header_3">
              <button
                class="accordion-button fs-4 fw-bolder bg-white text-gray-700 text-hover-primary text-uppercase"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#kt_accordion_3_body_3"
                aria-expanded="true"
                aria-controls="kt_accordion_3_body_3"
              >
                Metode Pembayaran
              </button>
            </h2>
            <div
              id="kt_accordion_3_body_3"
              class="accordion-collapse collapse"
              aria-labelledby="kt_accordion_3_header_3"
              data-bs-parent="#kt_accordion_3"
            >
                <div class="accordion-body p-0">
                    <div class="summary m-0">
                        <div class="summary-list-fixed row m-0">
                            <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3 fs-4 fw-bolder text-gray-700" v-for="(o, i) in summary.payment" :key="i">
                                <div class="d-flex">
                                    <span class="text-uppercase">{{ o.payment }}</span>
                                    <span class="ms-auto">{{ o.total ? formatIDR(o.total, { prefix: 'Rp'}) : 0 }}</span>
                                </div>
                                <div class="d-flex text-gray-400 text-uppercase">
                                    <span>Qty</span>
                                    <span class="ms-auto text-nowrap">{{ o.qty }} PCS</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
          </div>
        </div>
        <!--end::Accordion-->

        <!--begin::Accordion-->
        <div class="accordion mb-4" id="kt_accordion_4">
          <div class="accordion-item">
            <h2 class="accordion-header" id="kt_accordion_4_header_4">
              <button
                class="accordion-button fs-4 fw-bolder bg-white text-gray-700 text-hover-primary text-uppercase"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#kt_accordion_4_body_4"
                aria-expanded="true"
                aria-controls="kt_accordion_4_body_4"
              >
                Brand
              </button>
            </h2>
            <div
              id="kt_accordion_4_body_4"
              class="accordion-collapse collapse"
              aria-labelledby="kt_accordion_4_header_4"
              data-bs-parent="#kt_accordion_4"
            >
                <div class="accordion-body p-0">
                    <div class="summary m-0">
                        <div class="summary-list-fixed row m-0">
                            <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3 fs-4 fw-bolder text-gray-700" v-for="(o, i) in summary.brand" :key="i">
                                <div class="d-flex">
                                    <span class="text-uppercase">{{ o.brand_name }}</span>
                                    <span class="ms-auto">{{ o.total ? formatIDR(o.total, { prefix: 'Rp'}) : 0 }}</span>
                                </div>
                                <div class="d-flex text-gray-400 text-uppercase">
                                    <span>Qty</span>
                                    <span class="ms-auto text-nowrap">{{ o.qty }} PCS</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
          </div>
        </div>
        <!--end::Accordion-->

        <!--begin::Accordion-->
        <div class="accordion mb-4" id="kt_accordion_7">
          <div class="accordion-item">
            <h2 class="accordion-header" id="kt_accordion_7_header_7">
              <button
                class="accordion-button fs-4 fw-bolder bg-white text-gray-700 text-hover-primary text-uppercase"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#kt_accordion_7_body_7"
                aria-expanded="true"
                aria-controls="kt_accordion_7_body_7"
              >
                Ukuran
              </button>
            </h2>
            <div
              id="kt_accordion_7_body_7"
              class="accordion-collapse collapse"
              aria-labelledby="kt_accordion_7_header_7"
              data-bs-parent="#kt_accordion_7"
            >
                <div class="accordion-body p-0">
                    <div class="summary m-0">
                        <div class="summary-list-fixed row m-0">
                            <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3 fs-4 fw-bolder text-gray-700" v-for="(o, i) in summary.size" :key="i">
                                <div class="d-flex">
                                    <span class="text-uppercase">{{ o.size }}</span>
                                    <span class="ms-auto text-nowrap">{{ o.qty }}</span>
                                </div>
                                <div class="py-2">
                                    <ul class="ps-3 mt-1 text-gray-600 fs-7 text-uppercase" v-for="(item, i) in o.category.slice(0, 2)" :key="i">
                                        <li>
                                            <div class="d-flex justify-content-between">
                                                <div>{{ item.category }}</div>
                                                <div>{{ item.qty }}</div>
                                            </div>
                                        </li>
                                    </ul>
                                    <span class="text-primary text-hover-dark fs-7 cursor-pointer" @click="getDetailSize(o)">Lihat detail</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
          </div>
        </div>
        <!--end::Accordion-->

        <!--begin::Accordion-->
        <div class="accordion mb-4" id="kt_accordion_5">
          <div class="accordion-item">
            <h2 class="accordion-header" id="kt_accordion_5_header_5">
              <button
                class="accordion-button fs-4 fw-bolder bg-white text-gray-700 text-hover-primary text-uppercase"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#kt_accordion_5_body_5"
                aria-expanded="true"
                aria-controls="kt_accordion_5_body_5"
              >
                Kategori
              </button>
            </h2>
            <div
              id="kt_accordion_5_body_5"
              class="accordion-collapse collapse"
              aria-labelledby="kt_accordion_5_header_5"
              data-bs-parent="#kt_accordion_5"
            >
                <div class="accordion-body p-0">
                    <div class="summary m-0">
                        <div class="summary-list-fixed row m-0">
                            <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3 fs-4 fw-bolder text-gray-700" v-for="(o, i) in summary.category" :key="i">
                                <div class="d-flex">
                                    <span class="text-uppercase">{{ o.category }}</span>
                                    <span class="ms-auto text-nowrap">{{ o.qty }}</span>
                                </div>
                                <div class="py-2">
                                    <ul class="ps-3 mt-1 text-gray-600 fs-7 text-uppercase" v-for="(item, i) in o.brand.slice(0, 2)" :key="i">
                                        <li>
                                            <div class="d-flex justify-content-between">
                                                <div>{{ item.brand_name }}</div>
                                                <div>{{ item.qty }}</div>
                                            </div>
                                        </li>
                                    </ul>
                                    <span class="text-primary text-hover-dark fs-7 cursor-pointer" @click="getDetailCategory(o)">Lihat detail</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
          </div>
        </div>
        <!--end::Accordion-->

        <!--begin::Accordion-->
        <div class="accordion mb-4" id="kt_accordion_6">
          <div class="accordion-item">
            <h2 class="accordion-header" id="kt_accordion_6_header_6">
              <button
                class="accordion-button fs-4 fw-bolder bg-white text-gray-700 text-hover-primary text-uppercase"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#kt_accordion_6_body_6"
                aria-expanded="true"
                aria-controls="kt_accordion_6_body_6"
              >
                Warna
              </button>
            </h2>
            <div
              id="kt_accordion_6_body_6"
              class="accordion-collapse collapse"
              aria-labelledby="kt_accordion_6_header_6"
              data-bs-parent="#kt_accordion_6"
            >
                <div class="accordion-body p-0">
                    <div class="summary m-0">
                        <div class="summary-list-fixed row m-0">
                            <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3 fs-4 fw-bolder text-gray-700" v-for="(o, i) in summary.color" :key="i">
                                <div class="d-flex">
                                    <span class="text-uppercase">{{ o.color }}</span>
                                    <span class="ms-auto text-nowrap">{{ o.qty }}</span>
                                </div>
                                <div class="py-2">
                                    <ul class="ps-3 mt-1 text-gray-600 fs-7 text-uppercase" v-for="(item, i) in o.category.slice(0, 2)" :key="i">
                                        <li>
                                            <div class="d-flex justify-content-between">
                                                <div>{{ item.category }}</div>
                                                <div>{{ item.qty }}</div>
                                            </div>
                                        </li>
                                    </ul>
                                    <span class="text-primary text-hover-dark fs-7 cursor-pointer" @click="getDetailColor(o)">Lihat detail</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
          </div>
        </div>
        <!--end::Accordion-->
    </div>

    <el-dialog v-model="modalCategory" :title="tempData.category.category + ' (' + tempData.category.qty + ')'" width="400px" :close-on-click-modal="true" v-if="tempData.category">
        <div class="py-2">
            <ul class="ps-3 mt-1 text-gray-600 fs-7 text-uppercase" v-for="(item, i) in tempData.category.brand" :key="i">
                <li>
                    <div class="d-flex justify-content-between">
                        <div>{{ item.brand_name }}</div>
                        <div>{{ item.qty }}</div>
                    </div>
                </li>
            </ul>
        </div>
        <div class="py-2">
            <div class="d-flex">
                <h5 class="fw-normal mb-4">Artikel</h5>
            </div>
            <div v-loading="loadingArtikel" >
                <ul class="ps-3 mt-1 text-gray-600 fs-7 text-uppercase" v-for="(item, i) in tempData.category.artikel" :key="i">
                    <li>
                        <div class="d-flex justify-content-between">
                            <div>{{ item.artikel }}</div>
                            <div>{{ item.qty }}</div>
                        </div>
                    </li>
                </ul>
            </div>
            <div class="text-center mt-7" v-show="tempData.category.artikel">
                <el-pagination 
                    background 
                    small
                    layout="prev, pager, next" 
                    :page-count="paginationArtikel.total_page" 
                    @next-click="handlePageArtikelChange" 
                    @prev-click="handlePageArtikelChange" 
                    @current-change="handlePageArtikelChange" 
                    @update:current-page="paginationArtikel.page"
                />
            </div>
        </div>
    </el-dialog>    

    <el-dialog v-model="modalColor" :title="tempData.color.color + ' (' + tempData.color.qty + ')'" width="400px" :close-on-click-modal="true" v-if="tempData.color">
        <div class="py-2">
            <ul class="ps-3 mt-1 text-gray-600 fs-7 text-uppercase" v-for="(item, i) in tempData.color.category" :key="i">
                <li>
                    <div class="d-flex justify-content-between">
                        <div>{{ item.category }}</div>
                        <div>{{ item.qty }}</div>
                    </div>
                </li>
            </ul>
        </div>
    </el-dialog>

    <el-dialog v-model="modalSize" :title="tempData.size.size + ' (' + tempData.size.qty + ')'" width="400px" :close-on-click-modal="true" v-if="tempData.size">
        <div class="py-2">
            <ul class="ps-3 mt-1 text-gray-600 fs-7 text-uppercase" v-for="(item, i) in tempData.size.category" :key="i">
                <li>
                    <div class="d-flex justify-content-between">
                        <div>{{ item.category }}</div>
                        <div>{{ item.qty }}</div>
                    </div>
                </li>
            </ul>
        </div>
    </el-dialog>

</div>
</template>

<script>
import { defineComponent, onBeforeMount, ref, reactive, watchEffect, watch, computed, onUpdated } from "vue";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { formatIDR } from "@/helpers/functions";
//import TableLite from 'vue3-table-lite/src/components/TableLite.vue'
import Datatable from "@/components/kt-datatable/KTDatatable.vue"
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import * as Yup from "yup";
import { ErrorMessage, Field, Form } from "vee-validate";
import InnerImageZoom from 'vue-inner-image-zoom';
import { InfoFilled, QuestionFilled } from '@element-plus/icons-vue'
import { ElLoading } from 'element-plus'
import { ElMessage, ElMessageBox } from 'element-plus'
import fileDownload from 'js-file-download';
import Axios from 'axios'
import moment from 'moment'
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import LoadingSummary from "@/components/LoadingSummary.vue"

export default defineComponent({
    components: { 
        Datatable,
        //QuestionFilled,
        //Field,
        //Form,
        //ErrorMessage,
        //'inner-image-zoom': InnerImageZoom,
        LoadingSummary
    },
    setup() {
        const img_url_mockup = ref(process.env.VUE_APP_IMAGE_MOCKUPS)
        const img_url_desain= ref(process.env.VUE_APP_IMAGE_DESAINS)

        const store = useStore()

        const loadingSubmit = ref(false)
        const loadingTable = ref(false)
        const loadingSummary = ref(false)
        const loadingArtikel = ref(false)

        const tableRef = ref(null)

        const modalCategory = ref(false)
        const modalColor = ref(false)
        const modalSize = ref(false)

        const filterActive = ref(false)
        const filter = reactive({
            store_id: null,
            kode_inv: null,
            brand_id: null,
            date: null,
            payment_id: null,
        })

        const summary = ref({
          total_item: 0,
          total_qty: 0,
          total_gross: 0,
          total_nett: 0,
          total_inv: 0,
          store: 0,
          tipe_produk: [],
          brand: [],
          category: [],
          artikel: [],
          color: [],
          size: [],
          payment: [],
          project: null,
        })

        const formRef = ref(null);
        const tempData = reactive(Yup.object().shape({
            store_id: Yup.number().required().label("Toko"),
            date: null,
            category: null,
            color: null,
            size: null,
        }))
        
        const tableHeader = ref([
            {
                name: "#",
                key: "index",
            },
            {
                name: "Toko",
                key: "store",
            },
            {
                name: "Tanggal",
                key: "tgl",
            },
            {
                name: "Kode Transaksi",
                key: "kode_inv",
            },
            {
                name: "Pembayaran",
                key: "payment_method",
            },
            {
                name: "Qty",
                key: "total_qty",
            },
            {
                name: "Total",
                key: "total",
            },
            {
                name: "Status",
                key: "status",
            },
        ]);

        const tableData = reactive([]);
        const totalRecordCount = ref(0);

        // FILTER
        const dateShortcuts = [
          {
            text: 'Last week',
            value: () => {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              return [start, end]
            },
          },
          {
            text: 'Last month',
            value: () => {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              return [start, end]
            },
          },
          {
            text: 'Last 3 months',
            value: () => {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
              return [start, end]
            },
          },
        ]

        const listStore = computed(() => store.getters.getListStore)
        const listBrand = computed(() => store.getters.getListBrand)
        const listPaymentMethod = computed(() => store.getters.getListPaymentMethod)

        const onFilter = async () => {
            store.dispatch(Actions.ADD_FILTER, filter);
            filterActive.value = false

            await getData()

            Object.values(filter).every(o => o == null || o.length == 0)
                ? filterActive.value = false
                : filterActive.value = true
        }

        const resetFilter = async() => {
            filter.store_id = null
            filter.kode_inv = null
            filter.date = null
            
            loadingTable.value = true

            filterActive.value = false

            await getData()

            loadingTable.value = false
        }

        // PAGINATION
        const currentPage = ref(1)

        const pagination = ref({
            per_page: 50,
            total_page: 0,
            total_row: 0,
            page: 1,
        })

        const handlePageChange = async (page) => {
            loadingTable.value = true
            tableRef.value && (tableRef.value.scrollTop = 0) // Reset scroll position

            await getData(page)
            loadingTable.value = false
        }

        const getData = async (page = 1) => {
            loadingTable.value = true
            loadingSummary.value = true
            try {
                await tableData.splice(0)
                await Object.keys(summary.value).map(k => summary[k] = null)

                let start_date = filter.date ? '&start_date=' + moment(filter.date[0]).format('YYYY-MM-DD') : '';
                let end_date = filter.date ? '&end_date=' + moment(filter.date[1]).format('YYYY-MM-DD') : '';
                let kode_inv = filter.kode_inv ? '&kode_inv=' + filter.kode_inv : '';
                let store_id = filter.store_id ? '&store_id=' + filter.store_id : '';
                let brand = filter.brand_id ? '&brand=' + filter.brand_id : '';
                let payment_method = filter.payment_id ? '&payment_method=' + filter.payment_id : '';
                
                await ApiService.get("report_penjualan?page=" + page + brand + payment_method + start_date + end_date + kode_inv + store_id )
                .then(async(response) => {
                    currentPage.value = 1
                    pagination.value = await response.data.data.pagination
                    await Object.assign(tableData, response.data.data.data)

                    getSummary()
                })
                .catch((error) => {
                    console.log('Error getting sales report');
                });

            } catch(e) {
                console.log(e)
                Swal.fire({
                    title: e.message,
                    icon: "error",
                    buttonsStyling: false,
                    showConfirmButton: false,
                    timer: 2000,
                })
            }
            
            loadingTable.value = false
        }

        const exportExcel = async(formEl) => {
            try {
              loadingSubmit.value = true

              if (!filter.date) {
                throw new Error("Silakan pilih tanggal")
              }

              if (!filter.store_id) {
                throw new Error("Silakan pilih toko")
              }

            let getLink = ''
            let getFileName = ''

            let start_date = filter.date ? filter.date[0] : null;
            let end_date = filter.date ? filter.date[1] : null;

            await ApiService.post('export_excel_penjualan', {
              start_date: start_date,
              end_date: end_date,
              store_id: filter.store_id,
              brand_id: filter.brand_id,
              payment_id: filter.payment_id,
            })
              .then(({ data }) => {
                if (data.error) {
                  throw(data.messages)
                }

                getLink = data.data.link
                getFileName = data.data.fileName

                window.open(getLink, '_blank').focus();

                Swal.fire({
                  title: 'Berhasil didownload',
                  icon: "success",
                  buttonsStyling: false,
                  showConfirmButton: false,
                  timer: 2000,
                })
              })
              .catch((error) => {
                Swal.fire({
                  title: error,
                  icon: "error",
                  buttonsStyling: false,
                  showConfirmButton: false,
                  timer: 2000,
                })
              });
                
            } catch(e) {
                console.log(e)
                Swal.fire({
                    title: e.message,
                    icon: "error",
                    buttonsStyling: false,
                    showConfirmButton: false,
                    timer: 2000,
                })
            }

            loadingSubmit.value = false
        }

        const getSummary = async () => {
          try {
            loadingSummary.value = true

            let store_id = filter.store_id ? '&store_id=' + filter.store_id : '';
            let start_date = filter.date ? '&start_date=' + moment(filter.date[0]).format('YYYY-MM-DD') : '';
            let end_date = filter.date ? '&end_date=' + moment(filter.date[1]).format('YYYY-MM-DD') : '';
            let brand = filter.brand_id ? '&brand=' + filter.brand_id : '';
            let payment_method = filter.payment_id ? '&payment_method=' + filter.payment_id : '';

            await ApiService.get("summary_penjualan?" + store_id + brand + payment_method + start_date + end_date)
              .then(async({ data }) => {
                if(data.error) {
                    throw data.messages;
                }
                summary.value.total_inv = data.data.total_inv || 0
                summary.value.total_item = data.data.total_item || 0
                summary.value.total_qty = data.data.total_qty
                summary.value.total_discount = data.data.total_discount
                summary.value.total_gross = data.data.total_gross
                summary.value.total_nett = data.data.total_nett
                summary.value.store = data.data.store
                summary.value.tipe_produk = data.data.tipe_produk
                summary.value.brand = data.data.brand
                summary.value.category = data.data.category
                summary.value.artikel = data.data.artikel
                summary.value.color = data.data.color
                summary.value.size = data.data.size
                summary.value.payment = data.data.payment
                summary.value.projectf = data.data.project
              })
              .catch((response) => {
                throw new Error(response)
              });

          } catch(e) {
            Swal.fire({
                title: e.message,
                icon: "error",
                buttonsStyling: false,
                showConfirmButton: false,
                timer: 2000,
            })
          }


            loadingSummary.value = false
        }

        // PAGINATION SUMMARY
        const paginationArtikel = ref({
            per_page: 50,
            total_page: 0,
            total_row: 0,
            page: 1,
        })

         const handlePageArtikelChange = async (page) => {
            tableRef.value && (tableRef.value.scrollTop = 0) // Reset scroll position
            paginationArtikel.value.page = page;

            await getArtikelByCategory(tempData.category.category_id)
        }

        const getDetailCategory = async(data) => {
            try {
                tempData.category = data
                modalCategory.value = true

                getArtikelByCategory(data.category_id)

            } catch(e) {
                console.error(e)
            }
        }

        const getArtikelByCategory = async(category_id) => {
            try {
                loadingArtikel.value = true;

                let page = paginationArtikel.value.page ? '?page=' + paginationArtikel.value.page : '?page=1';
                let category = category_id ? '&category=' + category_id : '&category=1';
                let store_id = filter.store_id ? '&store_id=' + filter.store_id : '';
                let start_date = filter.date ? '&start_date=' + moment(filter.date[0]).format('YYYY-MM-DD') : '';
                let end_date = filter.date ? '&end_date=' + moment(filter.date[1]).format('YYYY-MM-DD') : '';
                let brand = filter.brand_id ? '&brand=' + filter.brand_id : '';
                let payment_method = filter.payment_id ? '&payment_method=' + filter.payment_id : '';
                
                await ApiService.get("summary_artikel_by_category" + page + category + store_id + brand + payment_method + start_date + end_date)
                .then(async(response) => {
                    paginationArtikel.value = await response.data.data.pagination
                    tempData.category.artikel = response.data.data.data
                })
                .catch((error) => {
                    console.log('Error getting sales report');
                });

                loadingArtikel.value = false;

            } catch(e) {
                console.error(e)
            }
        }

        const getDetailColor = (data) => {
            try {
                tempData.color = data
                modalColor.value = true

            } catch(e) {
                console.error(e)
            }
        }

        const getDetailSize = (data) => {
            try {
                tempData.size = data
                modalSize.value = true

            } catch(e) {
                console.error(e)
            }
        }

        onBeforeMount(() => {
            setCurrentPageBreadcrumbs("Laporan Penjualan", ['Laporan Penjualan']);

            store.dispatch(Actions.LIST_BRAND);
            store.dispatch(Actions.LIST_STORE);
            store.dispatch(Actions.LIST_PAYMENT_METHOD);

            getData()
        })

        return {
            formatIDR, moment, dateShortcuts,
            loadingSubmit, loadingTable, loadingSummary, loadingArtikel, tableRef, img_url_mockup, img_url_desain,
            onFilter, resetFilter, filter, filterActive, modalCategory, modalColor, modalSize,
            tempData, getDetailCategory, getDetailColor, getDetailSize,
            formRef, listStore, listBrand, listPaymentMethod, exportExcel, summary,
            tableHeader, tableData, totalRecordCount,
            handlePageChange, currentPage, pagination,
            getArtikelByCategory, handlePageArtikelChange, paginationArtikel,
        }
    }
})
</script>